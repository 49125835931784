import React from "react"
import Layout from "../components/Layout"
import { css } from "@emotion/core"

import useBooks from '../hooks/use-books';
import MediaStub from "../components/MediaStub";

export default function Books() {
  const books = useBooks();

  return(
    <>  
      <Layout>
        <h1>BOOKS I'VE READ</h1>
        <div css={css`
        display: flex;
        flex-wrap: wrap;
        
        gap: 1em;
        justify-content: space-between;
        > article {
          flex: 1;
          padding: 1rem;
          background: #6B87A8;
          color: white;
          border-radius: 5px;
          h3 {
            color: white;
          }
          
        }
      `}>
          {books.map(book => (
            <MediaStub media={book} dateText="Finished" />
          ))}
        </div>
      </Layout>
    </>
  )
}
